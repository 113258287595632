<div class="dataTable__cap">
    <div *ngIf="hasSearch" class="filterHeader">
        <mat-form-field appearance="fill">
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="applyFilter($event.target)" placeholder="Buscar..." class="focus:ring-transparent">
        </mat-form-field>
    </div>
    
    <mat-table #dataTable [dataSource]="dataSource" matSort multiTemplateDataRows>
        <!-- Toggle Button Start -->
        <ng-container matColumnDef="trigger">
            <mat-header-cell *matHeaderCellDef fxFlex="70px">
                <mat-icon>menu</mat-icon>
            </mat-header-cell>
            <mat-cell *matCellDef="let row; let i = dataIndex;" fxFlex="70px">
                <span (click)="expandedElement[row.id] = !expandedElement[row.id]" [ngClass]="[expandedElement[row.id] ?  'active' : 'inActive']"  class="btnToggleExpand"></span>
            </mat-cell>
        </ng-container>
        <!-- Toggle Button End -->
        
        <ng-container [matColumnDef]="column.id" *ngFor="let column of visibleColumns">
            <!-- Header cell -->
            <ng-container *ngIf="column.id == 'action_buttons'; else elseActionsColumn">
                <mat-header-cell *matHeaderCellDef [fxFlex]="column.width + 'px'" fxLayoutAlign="end" mat-sort-header [disabled]="!hasSorting">
                    <ng-container *ngIf="customHeader; else elseCustomHeader1">
                        <ng-container *ngTemplateOutlet="customHeader; context: { $implicit: column.label, column: column }"></ng-container>
                    </ng-container>
                    <ng-template #elseCustomHeader1>
                        {{ column.label }}
                    </ng-template>
                </mat-header-cell>

                <mat-cell *matCellDef="let row;let i = dataIndex" [fxFlex]="column.width + 'px'" fxLayoutAlign="end">
                    <ng-container *ngIf="customTemplate; else elseCustomHTML1">
                        <div class="inline-flex flex-wrap items-center gap-2 w-auto max-w-full py-1">
                            <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: (row[column.id] ?? null), row: row, column: column, index: i }"></ng-container>
                        </div>
                    </ng-container>
                    <ng-template #elseCustomHTML1>
                        <span class="px-1 two_line-hidden max-w-full">{{ row[column.id] ?? '-' }}</span>
                    </ng-template>
                </mat-cell>
            </ng-container>
            <ng-template #elseActionsColumn>
                <mat-header-cell *matHeaderCellDef [fxFlex]="column.width + 'px'" mat-sort-header [disabled]="!hasSorting">
                    <ng-container *ngIf="customHeader; else elseCustomHeader1">
                        <ng-container *ngTemplateOutlet="customHeader; context: { $implicit: column.label, column: column }"></ng-container>
                    </ng-container>
                    <ng-template #elseCustomHeader1>
                        {{ column.label }}
                    </ng-template>
                </mat-header-cell>

                <mat-cell *matCellDef="let row;let i = dataIndex" [fxFlex]="column.width + 'px'">
                    <ng-container *ngIf="customTemplate; else elseCustomHTML2">
                        <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: (row[column.id] ?? null), row: row, column: column, index: i }"></ng-container>
                    </ng-container>
                    <ng-template #elseCustomHTML2>
                        <span class="px-1 two_line-hidden max-w-full">{{ row[column.id] ?? '-' }}</span>
                    </ng-template>
                </mat-cell>
            </ng-template>
        </ng-container>
        
        <ng-container matColumnDef="hidden">
            <mat-cell *matCellDef="let row;let i = dataIndex;" class="matCell py-2" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="10px">
                <span class="p-2">
                    <div *ngFor="let hiddenColumn of hiddenColumns" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="10px">
                        <strong>
                            {{ hiddenColumn.label }}: 
                        </strong>
                        <ng-container *ngIf="customTemplate; else elseCustomHTML2">
                            <ng-container *ngIf="hiddenColumn.id == 'action_buttons'; else elseActionsColumnHidden">
                                <div class="inline-flex flex-wrap items-center gap-2 w-auto max-w-full py-1">
                                    <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: (row[hiddenColumn.id] ?? null), row: row, column: hiddenColumn, index: i }"></ng-container>
                                </div>
                            </ng-container>
                            <ng-template #elseActionsColumnHidden>
                                <ng-container *ngTemplateOutlet="customTemplate; context: { $implicit: (row[hiddenColumn.id] ?? null), row: row, column: hiddenColumn, index: i }"></ng-container>
                            </ng-template>
                        </ng-container>
                        <ng-template #elseCustomHTML2>
                            <span class="two_line-hidden">{{ row[hiddenColumn.id] ?? '-' }}</span>
                        </ng-template>
                    </div>
                </span>
            </mat-cell>
        </ng-container>
        
        <mat-header-row *matHeaderRowDef="visibleColumnsIds" class="matHeaderRow"></mat-header-row>
        
        <mat-row *matRowDef="let row; let i = dataIndex; columns: visibleColumnsIds" class="matRowVisible visible-row-{{row.id}}"></mat-row>
        
        <!-- Body row definition for hidden columns -->
        <mat-row *matRowDef="let row; let i = dataIndex; columns: ['hidden'];" [@detailExpand]="expandedElement.length && expandedElement[row.id] ? 'expanded' : 'collapsed'" style="overflow: hidden" class="matRowHidden hidden-row-{{row.id}} hidColLength-{{hiddenColumns.length}}" [ngClass]="expandedElement[row.id] ? 'expanded' : 'collapsed'"></mat-row>

        <mat-footer-row *matFooterRowDef="['noData']" [hidden]="!noData"></mat-footer-row>
        <mat-footer-row *matFooterRowDef="['loading']" [hidden]="!loadingData"></mat-footer-row>

        <ng-container matColumnDef="loading">
            <mat-footer-cell *matFooterCellDef colspan="100%" fxLayoutAlign="center center">
                <span class="data_loader">
                    <div class="btn__roller">
                        <div class="loader__spin"></div>
                    </div> Cargando Datos, espere por favor ...
                </span>
            </mat-footer-cell>
        </ng-container>
    
        <ng-container matColumnDef="noData">
            <mat-footer-cell *matFooterCellDef colspan="100%" class="text-center" fxLayoutAlign="center center">
                No se tiene datos.
            </mat-footer-cell>
        </ng-container>
        
    </mat-table>
    
    <mat-paginator *ngIf="hasPagination" [length]="length" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = $event"></mat-paginator>
    
</div>