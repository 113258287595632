import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonCapYeiComponent } from '../button/button.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { MatSelectModule } from '@angular/material/select';
import { FilterPipe } from '../../pipes/filter';
import { SearchDirective } from '../../directives/search.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { getEnvironment } from '../../environment/environment';
import { PaymentCardService } from '../../services/payment-card.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { GestureDirective } from '../../directives/gesture.directive';
import { IMaskModule } from 'angular-imask';
import { MatTooltipModule } from '@angular/material/tooltip';

export class Card {
  id:                     any;
  select:                 boolean = false;
  card_number!:           string;
  card_holder_name!:      string | null;
  card_expiration_month!: string;
  card_expiration_year!:  string;
  card_type:              string = 'default';
  card_security_code?:    string;
}

@Component({
  selector: 'payment-card',
  templateUrl: './payment-card.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ButtonCapYeiComponent,
    LazyLoadImageModule,
    MatSelectModule,
    FilterPipe,
    SearchDirective,
    FormsModule,
    ReactiveFormsModule,
    GestureDirective,
    IMaskModule,
    MatTooltipModule,
  ]
})
export class PaymentCardComponent implements OnInit, OnDestroy, OnChanges {

  env = getEnvironment();

  // data: ProcessCart[];

  // @Input() dataStatus!: { id: number, status: 'next' | 'current' | 'finished' }[];

  cardTypeLogo: SafeHtml;

  @Input() card!: Card;

  @Input() cardType: 'card__normal'|'card__normal-select'|'card__list'|'card__select-list'|'card__double-sided' = 'card__normal';
  
  @Output() onSelect = new EventEmitter<any>();
  @Output() onRemove = new EventEmitter<any>();

  @Input() reversedCard:boolean = false;

  currentMonth: string = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1).toString() : (new Date().getMonth() + 1).toString();
  currentYear: string = new Date().getFullYear().toString();

  mask = {
    mask: [
      {
        mask: '0000 000000 00000',
        regex: '^3[47]\\d{0,13}',
        cardtype: 'american-express'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
        cardtype: 'discover'
      },
      {
        mask: '0000 000000 0000',
        regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
        cardtype: 'diners'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
        cardtype: 'mastercard'
      },
      {
        mask: '0000 000000 00000',
        regex: '^(?:2131|1800)\\d{0,11}',
        cardtype: 'jcb15'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:35\\d{0,2})\\d{0,12}',
        cardtype: 'jcb'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
        cardtype: 'maestro'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^4\\d{0,15}',
        cardtype: 'visa'
      },
      {
        mask: '0000 0000 0000 0000',
        regex: '^62\\d{0,14}',
        cardtype: 'unionpay'
      },
      {
        mask: '0000 0000 0000 0000',
        cardtype: 'unknown'
      }
    ],
    dispatch: function (appended: any, dynamicMasked: any) {
      var number = (dynamicMasked.value + appended).replace(/\D/g, '');
  
      for (var i = 0; i < dynamicMasked.compiledMasks.length; i++) {
        let re = new RegExp(dynamicMasked.compiledMasks[i].regex);
        if (number.match(re) != null) {
          return dynamicMasked.compiledMasks[i];
        }
      }
    }
  };

  mask2 = {
    mask: [
      {
        mask: '0XXX XXXXXX 00000',
        regex: '^3[47]\\d{0,13}',
        cardtype: 'american-express'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}',
        cardtype: 'discover'
      },
      {
        mask: '0XXX XXXXXX 0000',
        regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}',
        cardtype: 'diners'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}',
        cardtype: 'mastercard'
      },
      {
        mask: '0XXX XXXXXX 00000',
        regex: '^(?:2131|1800)\\d{0,11}',
        cardtype: 'jcb15'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^(?:35\\d{0,2})\\d{0,12}',
        cardtype: 'jcb'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}',
        cardtype: 'maestro'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^4\\d{0,15}',
        cardtype: 'visa'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        regex: '^62\\d{0,14}',
        cardtype: 'unionpay'
      },
      {
        mask: '0XXX XXXX XXXX 0000',
        cardtype: 'unknown'
      }
    ],
    dispatch: function (appended: any, dynamicMasked: any) {
      var number = (dynamicMasked.value + appended).replace(/\D/g, '');
      var length = number.length;

      for (var i = 0; i < dynamicMasked.compiledMasks.length; i++) {
        let maskLength = dynamicMasked.compiledMasks[i].mask.replace(/\s/g, '').length;
        if (length <= maskLength) {
          return dynamicMasked.compiledMasks[i];
        }
      }
    }
  };

  constructor(
    private paymentCardService: PaymentCardService,
    private sanitizer: DomSanitizer
  ) {
    this.cardTypeLogo = this.sanitizer.bypassSecurityTrustHtml(this.paymentCardService.getCardTypeLogo('default'));
  }

  ngAfterContentInit() {
    this.cardTypeLogo = this.sanitizer.bypassSecurityTrustHtml(this.paymentCardService.getCardTypeLogo((this.card?.card_type ?? 'default'), 'single'));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['card'] && changes['card'].currentValue) {
      this.updateStatus();
    }
  }

  updateStatus() {
    this.cardTypeLogo = this.sanitizer.bypassSecurityTrustHtml(this.paymentCardService.getCardTypeLogo((this.card?.card_type ?? 'default'), 'single'));
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}
