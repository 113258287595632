@if(cardType == 'card__double-sided') {
    <div class="content__payment-card-double-sided" [ngClass]="{'reversed__card': reversedCard}">
        <div class="content__payment-card-front">
            <div class="first__card">
                <div class="content__logo-type-card">
                    <div class="logo__type-card" [innerHTML]="cardTypeLogo"></div>
                </div>
                <div class="content__chip-card">
                    <img src="/assets/img/general/chip-card.png" alt="CHIP">
                </div>
                <div class="number__card" [ngClass]="{'placeholder__number': (card.card_number == '')}">
                    {{ (card.card_number != '') ? (card.card_number | imask: mask) : '0000 0000 0000 0000' }}
                </div>
            </div>
            <div class="detail__card">
                <div class="date__card" >
                    <span>Válido hasta <i class="fa-solid fa-caret-right"></i></span>
                </div>
                <div class="date__card">
                    <p title="Válido hasta">
                        <span [ngClass]="{'placeholder__date': card.card_expiration_month == ''}">{{ (card.card_expiration_month != '') ? card.card_expiration_month : currentMonth }}/</span><span [ngClass]="{'placeholder__date': card.card_expiration_year == ''}">{{ (card.card_expiration_year != '') ? card.card_expiration_year : currentYear }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="content__payment-card-reverse">
            <div class="detail__card">
                <div class=""></div>
                <div class="cvv__card">
                    <p title="Código de seguridad" [ngClass]="{'placeholder__sc': card.card_security_code == ''}">{{ (card.card_security_code != '') ? card.card_security_code : '000' }}</p>
                </div>
            </div>
            <div class="first__card">
                <div class="content__logo-type-card detail__card">
                    <div class="name__card">
                        @if (card.card_holder_name != null) {
                            <span>Nombre en la tarjeta</span>
                            <p title="Nombre en la tarjeta" [ngClass]="{'placeholder__name': card.card_holder_name == ''}">{{ (card.card_holder_name != '') ? card.card_holder_name : 'Nombre completo' }}</p>
                        }
                    </div>
                    <div class="logo__type-card" [innerHTML]="cardTypeLogo"></div>
                </div>
            </div>
        </div>
    </div>
} @else {
    <div class="content__payment-card {{ cardType }}" appGesture [gestureOpts]="[{name: 'tap'}]" (tap)="(!card.select)&&(onSelect.observers.length > 0) ? onSelect.emit(card) : null" [ngClass]="{'selected__card': card.select}" [matTooltip]="card.select ? 'Tarjeta de Pagos actual' : ''">
        <div class="delete__payment-card">
            <button-cy addClass="btn__fit btn__error btn__icon-rounded" *ngIf="(!card.select)&&(onRemove.observers.length > 0)" (onPress)="$event.stopPropagation(); onRemove.emit(card);" icon="fa-light fa-trash" [iconOnly]="true" label="Eliminar tarjeta" tooltipPosition="right" />
        </div>
        <div class="first__card">
            <div class="content__logo-type-card">
                <div class="logo__type-card" [innerHTML]="cardTypeLogo"></div>
            </div>
            <div class="number__card">
                {{ card.card_number | imask: mask2 }}
            </div>
        </div>
        <div class="detail__card">
            <div class="name__card">
                <span>Nombre en la tarjeta</span>
                <p title="Nombre en la tarjeta">{{ card.card_holder_name }}</p>
            </div>
            <div class="date__card">
                <span>Válido hasta</span>
                <p title="Válido hasta">{{ card.card_expiration_month }}/{{ card.card_expiration_year }}</p>
            </div>
        </div>
    </div>
}